@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500&display=swap");

.button-rp-admin {
  @apply rounded-md px-4 py-2 border border-gray-200  active:scale-95;
}

.search-box {
  @apply flex items-center rounded-md border border-gray-200 outline-none px-4 w-64;
}

.input-box {
  @apply rounded-md border border-gray-200 outline-none h-11 w-full px-4
}

body {
  font-weight: 300;
}

.pac-container {
  @apply rounded-md mt-2 shadow-sm px-2
}

.pac-item {
  @apply cursor-pointer
}